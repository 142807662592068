import { Box, styled } from "@mui/material"
import React from "react"

interface AspectRatioProps {
  children: React.ReactNode
  className?: string
  ratio: number
}

const AspectRatio = (props: AspectRatioProps) => {
  const { children, className, ratio } = props

  const paddingBottom = `${(1 / ratio) * 100}%`

  return (
    <_OuterWrapper className={className} sx={{ paddingBottom }}>
      <_InnerWrapper>{children}</_InnerWrapper>
    </_OuterWrapper>
  )
}
export default AspectRatio

const _OuterWrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: 0,
})) as typeof Box

const _InnerWrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
})) as typeof Box
