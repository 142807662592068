import Head from "next/head"

// @ts-ignore PT - #180597146
export default function PageTitle({ children }) {
  if (!children) {
    return null
  }

  return (
    <Head>
      <title>{children}</title>
    </Head>
  )
}
