import ResourceModel from "models/convention-api/ResourceModel"
import ChatAvailable from "models/convention-api/v1/ChatAvailable"
import Institution from "models/convention-api/v1/Institution"

export default class ConventionExhibitor extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_exhibitors"
  protected static rels = {
    institution: () => ({
      type: Institution,
      from: "institution_id",
      filterOn: "id",
    }),
    chat_available: () => ({
      type: ChatAvailable,
      from: "institution_id",
      filterOn: "institution_id",
    }),
  }

  announcement!: string
  call_to_action_name!: string
  call_to_action_url!: string
  convention_convention_id!: string
  cover_photo_thumb_url!: string
  cover_photo_url!: string
  disabled!: boolean
  exhibitor_bio!: string
  exhibitor_name!: string
  institution_id!: string

  institution?: Institution
  chat_available?: ChatAvailable
}
