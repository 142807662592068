import ResourceModel from "models/convention-api/ResourceModel"
import ChatAvailable from "models/convention-api/v1/ChatAvailable"

export default class Institution extends ResourceModel {
  protected static basePath = "/v1/institutions"
  protected static rels = {
    chatAvailable: () => ({
      type: ChatAvailable,
      from: "id",
      filterOn: "institution_id",
    }),
    chat_available: () => ({
      type: ChatAvailable,
      from: "id",
      filterOn: "institution_id",
    }),
  }

  name!: string
  address1!: string
  address2!: string
  city!: string
  region!: string
  country!: string
  postal_code!: string
  time_zone!: string

  chatAvailable?: ChatAvailable
  chat_available?: ChatAvailable
}
