import ResourceModel from "models/convention-api/ResourceModel"
import ConventionVideoCount from "models/convention-api/v1/ConventionVideoCount"
import ConventionVideoTagging from "models/convention-api/v1/ConventionVideoTagging"
import Institution from "models/convention-api/v1/Institution"

export default class ConventionVideo extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_videos"
  protected static rels = {
    taggings: () => ({
      type: ConventionVideoTagging,
      from: "id",
      filterOn: "convention_video_id",
    }),
    video_count: () => ({
      type: ConventionVideoCount,
      from: "id",
      filterOn: "id",
    }),
    institution: () => ({
      type: Institution,
      from: "institution_id",
      filterOn: "id",
    }),
  }

  taggings?: ConventionVideoTagging
  video_count?: ConventionVideoCount
  institution?: Institution

  institution_id!: string
  provider_id!: string
  provider_exhibitor_id!: string
  name!: string
  url!: string
  thumbnail_url!: string | null
  external_video_id!: string
}
