import ResourceModel from "models/convention-api/ResourceModel"
import AdminUser from "models/convention-api/v1/AdminUser"
import Institution from "models/convention-api/v1/Institution"

export default class AdminUserInstitution extends ResourceModel {
  protected static basePath = "v1/admin_user_institutions"

  protected static rels = {
    institution: () => ({
      type: Institution,
      from: "institution_id",
      filterOn: "id",
    }),

    admin_user: () => ({
      type: AdminUser,
      from: "admin_user_id",
      filterOn: "id",
    }),
  }

  admin_user_id!: string
  institution_id!: string

  admin_user?: AdminUser
  institution?: Institution
}
