import { Renderer } from "lib/page-types"
import { UNAUTHORIZED } from "lib/status-codes"
import { useRouter } from "next/router"
import { useEffect } from "react"

// TODO: Convert renderers to be hooks since that
// better fits their use case than being a component

// @ts-ignore
export const RenderLoginPage: Renderer = ({ statusCode }) => {
  const router = useRouter()
  const needsAuthentication = statusCode === UNAUTHORIZED

  useEffect(() => {
    if (needsAuthentication) {
      router.push({
        pathname: "/login",
        query: {
          redirectAsPath: router.asPath,
        },
      })
    }
  }, [needsAuthentication])
  if (needsAuthentication) {
    return null
  }
  return undefined
}

export default [RenderLoginPage]
