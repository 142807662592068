import ResourceModel from "models/convention-api/ResourceModel"
import ConventionVideo from "models/convention-api/v1/ConventionVideo"

export default class ConventionVideoCount extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_video_counts"
  protected static rels = {
    video: () => ({
      type: ConventionVideo,
      from: "convention_video_id",
      filterOn: "id",
    }),
  }

  video?: ConventionVideo

  video_count!: number
  convention_video_id!: string
}
