import { VideoPlatform } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"
import ConventionExhibitor from "models/convention-api/v1/ConventionExhibitor"
import ConventionSessionCapacity from "models/convention-api/v1/ConventionSessionCapacity"
import ConventionSessionTaggings from "models/convention-api/v1/ConventionSessionTagging"
import Institution from "models/convention-api/v1/Institution"
import SessionAssignment from "models/convention-api/v1/SessionAssignment"
import SessionAttachment from "models/convention-api/v1/SessionAttachment"

export default class ConventionSession extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_sessions"
  protected static rels = {
    assignments: () => ({
      type: SessionAssignment,
      from: "id",
      filterOn: "convention_session_id",
    }),
    attachments: () => ({
      type: SessionAttachment,
      from: "id",
      filterOn: "convention_session_id",
    }),
    taggings: () => ({
      type: ConventionSessionTaggings,
      from: "id",
      filterOn: "convention_session_id",
    }),
    capacity: () => ({
      type: ConventionSessionCapacity,
      from: "id",
      filterOn: "id",
    }),
    convention_exhibitor: () => ({
      type: ConventionExhibitor,
      from: "convention_exhibitor_id",
      filterOn: "id",
    }),
  }

  description!: string
  end_at!: moment.Moment
  start_at!: moment.Moment
  integrated_meeting_url!: string
  video_platform!: VideoPlatform
  name!: string
  promotional_image_thumb_url!: string
  promotional_image_url!: string
  whereby_room_created!: boolean
  status!: string
  convention_exhibitor_id!: string

  assignments?: SessionAssignment[]
  capacity?: ConventionSessionCapacity
  institution?: Institution
  attachments?: SessionAttachment[]
  taggings?: ConventionSessionTaggings[]
  convention_exhibitor?: ConventionExhibitor
}
