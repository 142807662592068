import sendConventionApiRequest from "lib/send-convention-api-request"
import { Current } from "lib/use-current"

interface DeleteConventionSessionRegistrationParametars {
  current: Current
  sessionId: string
}

const deleteConventionSessionRegistration = async (
  parameters: DeleteConventionSessionRegistrationParametars,
) => {
  const { current, sessionId } = parameters

  const providerId = current.provider?.id
  if (!providerId) throw "providerId is not defined"
  if (!sessionId) throw "sessionId is not defined"

  await sendConventionApiRequest({
    current,
    method: "DELETE",
    url: `v1/convention_providers/${providerId}/convention_session_registrations/${sessionId}`,
  })
}

export default deleteConventionSessionRegistration
