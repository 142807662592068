import { VideoPlatform } from "app-constants/constants"
import useAnycableUrl from "hooks/useAnycableUrl"
import useCurrent from "lib/use-current"
import ConventionSession from "models/convention-api/v1/ConventionSession"
import { useEffect, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"

const useConventionSessionRealTimeCapacity = (session: ConventionSession) => {
  const current = useCurrent()
  const identifier = createIdentifier(session)
  const anycableURL = useAnycableUrl()
  // We support real time capacity only for whereby
  const websocketURL = session.video_platform === VideoPlatform.WHEREBY ? anycableURL : null

  const [realTimeTotalInRoom, setRealTimeTotalInRoom] = useState<null | number>(null)

  const websocket = useWebSocket(websocketURL, {
    queryParams: {
      ...(current?.authToken && { token: current.authToken }),
    },
  })

  const isWebsocketOpen = ReadyState.OPEN === websocket.readyState

  useEffect(() => {
    if (!isWebsocketOpen) return
    if (identifier === null) return
    websocket.sendJsonMessage({
      identifier,
      command: "subscribe",
    })
  }, [websocket.readyState, identifier])

  useEffect(() => {
    const parsedData = parseWebsocketData(websocket.lastMessage?.data)

    const clientsNumber = parsedData?.message?.clients_number
    if (typeof clientsNumber !== "number") return
    setRealTimeTotalInRoom(clientsNumber)
  }, [websocket.lastMessage?.data])

  if (!session || !session.capacity) return null

  const wherebyTotalInRoom = realTimeTotalInRoom ?? session.capacity.total_in_a_room ?? 0

  const isZoomFull = session.capacity.seats_taken >= session.capacity.total_capacity
  const isWherebyFull = wherebyTotalInRoom >= session.capacity.total_capacity
  const full = session.video_platform === VideoPlatform.WHEREBY ? isWherebyFull : isZoomFull
  const zoomSeatsLeft = Math.max(session.capacity.total_capacity - session.capacity.seats_taken, 0)
  const empty = wherebyTotalInRoom === 0

  return {
    wherebyTotalInRoom,
    zoomSeatsLeft,
    full,
    empty,
  }
}

export default useConventionSessionRealTimeCapacity

const createIdentifier = (conventionSession: ConventionSession) =>
  '{"channel":"Realtime::ConventionSessionStatusesChannel","id":' + conventionSession.id + "}"

const parseWebsocketData = (data: unknown) => {
  if (!data) return null
  if (typeof data !== "string") return null

  try {
    const parsedData = JSON.parse(data)

    return parsedData
  } catch (e) {
    console.warn(e)
    return null
  }
}
