import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { Box, CardMedia, styled, Typography } from "@mui/material"
import AspectRatio from "components/common/AspectRatio"
import { FallbackImage } from "components/common/FallbackImage"
import AppLink from "components/navigation/AppLink"
import useSessionCopyText from "hooks/sessions/useSessionCopyText"
import ConventionSession from "models/convention-api/v1/ConventionSession"
import Link from "next/link"
import React, { useState } from "react"
import { pxToRem } from "utils/helpers"

enum LayoutItem {
  CONTENT = "CONTENT",
  IMAGE = "IMAGE",
  ACTIONS = "ACTIONS",
}

export enum SessionCardStatus {
  LIVE = "LIVE",
  AT_CAPACITY = "AT_CAPACITY",
  REGISTRATION = "REGISTRATION",
  REGISTERED = "REGISTERED",
  LOADING = "LOADING",
}

export type SessionCardAction =
  | {
      status: SessionCardStatus.REGISTRATION
      onClick: LoadingButtonProps["onClick"]
    }
  | {
      status: SessionCardStatus.REGISTERED
      onClick: LoadingButtonProps["onClick"]
    }
  | {
      status: SessionCardStatus.LOADING
    }
  | {
      status: SessionCardStatus.AT_CAPACITY
    }
  | {
      status: SessionCardStatus.LIVE
      statusText?: string
      onClick: LoadingButtonProps["onClick"]
    }

interface SessionCardProps {
  session: ConventionSession
  href: string
  action: SessionCardAction
}

const SessionCard = (props: SessionCardProps) => {
  const { session, href, action } = props
  const sessionCopyText = useSessionCopyText()

  const [shouldShowFallbackImage, setShouldShowFallbackImage] = useState(false)

  const imageSrc = session.promotional_image_thumb_url ?? session.promotional_image_url
  const imageAlt = `${session.name}'s image`
  const onCardMediaError = () => {
    if (shouldShowFallbackImage) return

    console.error(`${session.name} has failed to load the cover image thumbnail`, imageSrc)
    setShouldShowFallbackImage(true)
  }

  const actionText = {
    [SessionCardStatus.LIVE]: "Join",
    [SessionCardStatus.REGISTERED]: "Cancel Registration",
    [SessionCardStatus.LOADING]: "Loading...",
    [SessionCardStatus.REGISTRATION]: "Register",
  }[action.status]

  const actionLoading = [SessionCardStatus.AT_CAPACITY, SessionCardStatus.LOADING].includes(
    action.status,
  )
  const actionStatusText = (() => {
    if (action.status === SessionCardStatus.LIVE) return action.statusText
    if (action.status === SessionCardStatus.AT_CAPACITY)
      return sessionCopyText?.singular
        ? `This ${sessionCopyText?.singular} is at capacity`
        : "At capacity"
    return undefined
  })()

  const handleActionClick: LoadingButtonProps["onClick"] = (event) => {
    if ("onClick" in action) action?.onClick(event)
  }

  return (
    <CustomContainer>
      <ContentContainer>
        <Typography variant="paragraphAllCaps" color="gray.darker">
          {session.convention_exhibitor?.exhibitor_name}
        </Typography>
        <Link href={href} passHref>
          <CustomName role="heading">
            {session.name}
          </CustomName>
        </Link>
        <Typography
          dateTime={`${session.start_at.format()}/${session.end_at.format()}`}
          component="time"
          variant="paragraphRegular"
          color="gray.darker">
          {session.start_at.format("MMMM D, YYYY, h:mm A z")}
        </Typography>
      </ContentContainer>
      <ImageContainer>
        <_ImageSubContainer>
          <_BackgroundImage aria-hidden="true" src={imageSrc} />
          <AspectRatio ratio={16 / 9}>
            {shouldShowFallbackImage || !imageSrc ? (
              <FallbackImage ariaLabel={`${session.name}'s fallback image`} />
            ) : (
              <CardMedia
                onError={onCardMediaError}
                component="img"
                image={imageSrc}
                alt={imageAlt}
                aria-hidden={!imageAlt}
              />
            )}
            <Link href={href} passHref aria-label={`Open ${session.name}`}>
              {`Open ${session.name}`}
            </Link>
          </AspectRatio>
        </_ImageSubContainer>
      </ImageContainer>
      <ActionsContainer>
        {actionText && (
          <LoadingButton loading={actionLoading} variant="outlined" onClick={handleActionClick}>
            {actionText}
          </LoadingButton>
        )}
        <AppLink href={href} aria-label={`Open ${session.name}`}>
          View Detail
        </AppLink>
        {actionStatusText && <Typography variant="paragraphItalic">{actionStatusText}</Typography>}
      </ActionsContainer>
    </CustomContainer>
  )
}

export default SessionCard

const CustomContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    '${LayoutItem.IMAGE}'
    '${LayoutItem.CONTENT}'
    '${LayoutItem.ACTIONS}'
  `,

  width: "100%",
  borderRadius: pxToRem(10),

  border: `${pxToRem(1)} solid ${theme.palette.gray.light}`,
  boxShadow: `0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1)`,
  overflow: "hidden",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: `minmax(max-content, ${pxToRem(270)}) 1fr`,
    gridTemplateAreas: `
    '${LayoutItem.IMAGE} ${LayoutItem.CONTENT} '
    '${LayoutItem.ACTIONS} ${LayoutItem.ACTIONS}'
  `,
  },
})) as typeof Box

const ContentContainer = styled(Box)(({ theme }) => ({
  gridArea: LayoutItem.CONTENT,

  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(14),
  padding: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(30)} ${pxToRem(20)}`,
  [theme.breakpoints.up("md")]: {
    padding: pxToRem(30),
  },
})) as typeof Box

const ImageContainer = styled(Box)(() => ({
  gridArea: LayoutItem.IMAGE,

  display: "flex",
})) as typeof Box

export const ActionsContainer = styled(Box)(({ theme }) => ({
  gridArea: LayoutItem.ACTIONS,

  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: pxToRem(15),
  padding: `${pxToRem(25)} ${pxToRem(30)}`,
  borderTop: `${pxToRem(1)} solid ${theme.palette.gray.light}`,
})) as typeof Box

const CustomName = styled(Typography)(({ theme }) => ({
  ...theme.typography.header2,
  margin: 0,
  textTransform: "capitalize",
  cursor: "pointer",
  color: theme.palette.gray.darkest,
})) as typeof Typography

const _ImageSubContainer = styled(Box)(() => ({
  position: "relative",

  display: "flex",
  justifyContent: "stretch",
  alignItems: "center",
  alignContent: "stretch",

  width: "100%",
  height: "100%",

  overflow: "hidden",

  "& > *": {
    flex: 1,
  },
})) as typeof Box

const _BackgroundImage = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  filter: `blur(${pxToRem(10)})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}))
