import { Button, ButtonProps } from "@mui/material"
import Link, { LinkProps } from "next/link"
import React from "react"

type SafeLinkProps = Pick<LinkProps, "href" | "passHref" | "as">
type SafeButtonProps = Omit<ButtonProps<"a">, "component" | "ref">
export type AppLinkProps = SafeLinkProps & SafeButtonProps

const AppLink = React.forwardRef<HTMLAnchorElement, AppLinkProps>(function AppLink(props, ref) {
  const { href, passHref = true, as, ...rest } = props

  const buttonProps = rest as SafeButtonProps
  return (
    <Link passHref={passHref} href={href} as={as} legacyBehavior>
      <Button {...buttonProps} component="a" ref={ref} />
    </Link>
  )
})

export default AppLink
