import ResourceModel from "models/convention-api/ResourceModel"
import ConventionVideoTagging from "models/convention-api/v1/ConventionVideoTagging"

export default class ConventionTag extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/tags"
  protected static rels = {
    video_taggings: () => ({
      type: ConventionVideoTagging,
      from: "id",
      filterOn: "convention_tag_id",
    }),
  }

  video_taggings?: ConventionVideoTagging
  name!: string
}
