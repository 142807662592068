import ResourceModel from "models/convention-api/ResourceModel"
import AdminUser from "models/convention-api/v1/AdminUser"
import TerritoryTag from "models/convention-api/v1/TerritoryTag"

export default class TerritoryTagging extends ResourceModel {
  protected static basePath = "/v1/territory_taggings"
  protected static rels = {
    tag: () => ({
      type: TerritoryTag,
      from: "territory_tag_id",
      filterOn: "id",
    }),
    admin_user: () => ({
      type: AdminUser,
      from: "admin_user_id",
      filterOn: "id",
    }),
  }

  admin_user_id!: string
  territory_tag_id!: string

  tag?: TerritoryTag
  admin_user?: AdminUser
}
