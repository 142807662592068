import { TerritoryTagCategory } from "app-constants/constants"
import ResourceModel from "models/convention-api/ResourceModel"
import TerritoryTagging from "models/convention-api/v1/TerritoryTagging"

export default class TerritoryTag extends ResourceModel {
  protected static basePath = "/v1/territory_tags"
  protected static rels = {
    territory_tagging: () => ({
      type: TerritoryTagging,
      from: "id",
      filterOn: "territory_tag_id",
    }),
  }

  name!: string
  category!: TerritoryTagCategory

  territory_tagging?: TerritoryTagging
}
