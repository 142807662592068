import ResourceModel from "models/convention-api/ResourceModel"
import AdminUser from "models/convention-api/v1/AdminUser"

export default class SessionAssignment extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/session_assignments"
  protected static rels = {
    admin_user: () => ({ type: AdminUser, from: "admin_user_id", filterOn: "id" }),
  }

  admin_user_id!: string
  convention_session_id!: string

  admin_user?: AdminUser
}
