import ResourceModel from "models/convention-api/ResourceModel"
import AdminUserInstitution from "models/convention-api/v1/AdminUserInstitution"
import TerritoryTagging from "models/convention-api/v1/TerritoryTagging"
import { Moment } from "moment-timezone"

export default class AdminUser extends ResourceModel {
  protected static basePath = "v1/admin_users"

  protected static rels = {
    territory_taggings: () => ({
      type: TerritoryTagging,
      from: "id",
      filterOn: "admin_user_id",
    }),
    admin_user_institutions: () => ({
      type: AdminUserInstitution,
      from: "id",
      filterOn: "admin_user_id",
    }),
  }

  first_name!: string
  last_name!: string
  avatar!: {
    thumb_url: string
  }
  bio!: string
  name!: string
  title!: string
  verified_at!: Moment
  territory_bio!: string

  territory_taggings?: TerritoryTagging[]
  admin_user_institutions?: AdminUserInstitution[]
}
