import ImageIcon from "@mui/icons-material/Image"
import { Box } from "@mui/material"
import { makeStyles } from "tss-react/mui-compat"
import React from "react"

const useStyles = makeStyles()(
  (theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",

      backgroundColor: theme.palette.primary.main,
    },
    icon: {
      color: theme.palette.gray.lightest,
      fontSize: 34,
    },
  })
)

interface FallbackImageProps {
  classes?: {
    container?: string
    icon?: string
  }
  ariaLabel: string
}

export const FallbackImage = (props: FallbackImageProps) => {
  const { classes = {}, ariaLabel } = props
  const { classes: _classes, cx } = useStyles()
  
  return (
    <Box
      role="img"
      aria-label={ariaLabel}
      aria-hidden={!ariaLabel}
      className={cx(_classes.container, classes.container)}>
      <ImageIcon className={cx(_classes.icon, classes.icon)} fontSize="inherit" />
    </Box>
  )
}
