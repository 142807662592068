// import { useSetCurrent } from "lib/use-current"
import PageTitle from "components/page-title"
import { Props } from "lib/page-types"
import renderers from "lib/renderers"
import React from "react"

export default function buildServerSidePage<T extends Props>(
  Page: (props: T) => JSX.Element,
): (props: T) => JSX.Element {
  return function ServerSidePage(props) {
    // const setCurrent = useSetCurrent()
    // if (props.current) {
    //   setCurrent(props.current)
    // }

    for (let index = 0; index < renderers.length; index++) {
      const renderer = renderers[index]
      const result = renderer(props)
      if (result !== undefined) {
        return result as JSX.Element
      }
    }

    return (
      <>
        <PageTitle>{props.title}</PageTitle>
        <Page {...props} />
      </>
    )
  }
}
