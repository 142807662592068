import ResourceModel from "models/convention-api/ResourceModel"
import ConventionTag from "models/convention-api/v1/ConventionTag"
import ConventionVideo from "models/convention-api/v1/ConventionVideo"

export default class ConventionVideoTagging extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_video_taggings"
  protected static rels = {
    tag: () => ({
      type: ConventionTag,
      from: "convention_tag_id",
      filterOn: "id",
    }),
    video: () => ({
      type: ConventionVideo,
      from: "convention_video_id",
      filterOn: "id",
    }),
  }

  video?: ConventionVideo
  tag?: ConventionTag

  convention_video_id!: string
  convention_tag_id!: string
}
