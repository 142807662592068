import useNow from "hooks/useNow"
import moment from "moment-timezone"
import { useEffect } from "react"
import { useState } from "react"

type DateUnit = moment.Moment | Date | number

function valueOf(datetime: DateUnit) {
  if (datetime) {
    if (moment.isMoment(datetime)) {
      return datetime.valueOf()
    }
    if (datetime instanceof Date) {
      return datetime.getTime()
    }
    if (typeof datetime === "number") {
      return datetime
    }
  }
  return undefined
}

interface Props {
  startAt?: DateUnit
  endAt?: DateUnit
  precisionInMs?: number
}

/**
  We have a number of things that respond if the current time is
  before, during, or after "something" so this hook works to make
  that process more generic.

  @example
  const conventionRelativeTime = useRelativeTime({
    startAt: convention?.start_at,
    endAt: convention?.end_at
  })
  if (conventionRelativeTime.before) {
    // ...
  } else if (conventionRelativeTime.after) {
    // ...
  } else if (conventionRelativeTime.during) {
    // ...
  }
*/
export default function useRelativeTime({ startAt, endAt, precisionInMs = 1000 }: Props) {
  const now = useNow({ precisionInMs })
  const [state, setState] = useState({
    before: false,
    after: false,
    during: false,
    invalid: true,
    status: "invalid",
  })

  useEffect(() => {
    // @ts-ignore
    const startAtValue = valueOf(startAt)
    // @ts-ignore
    const endAtValue = valueOf(endAt)

    let status = "invalid"
    if (now.value && startAtValue && endAtValue && startAtValue <= endAtValue) {
      if (now.value < startAtValue) {
        status = "before"
      } else if (now.value > endAtValue) {
        status = "after"
      } else {
        status = "during"
      }
    }

    if (state.status !== status) {
      setState({
        before: status === "before",
        after: status === "after",
        during: status === "during",
        invalid: status === "invalid",
        status: status,
      })
    }
  }, [now.value, startAt, endAt])

  return state
}
