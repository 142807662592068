import ResourceModel from "models/convention-api/ResourceModel"
import ConventionSession from "models/convention-api/v1/ConventionSession"
import ConventionTag from "models/convention-api/v1/ConventionTag"

export default class ConventionSessionTaggings extends ResourceModel {
  protected static basePath = "/v1/convention_providers/:provider_id/convention_session_taggings"
  protected static rels = {
    tag: () => ({
      type: ConventionTag,
      from: "convention_tag_id",
      filterOn: "id",
    }),
    video: () => ({
      type: ConventionSession,
      from: "convention_session_id",
      filterOn: "id",
    }),
  }

  session?: ConventionSession
  tag?: ConventionTag

  convention_session_id!: string
  convention_tag_id!: string
}
