import { getSessionCopyText } from "lib/sessions/getSessionCopyText"
import useCurrent from "lib/use-current"

const useSessionCopyText = () => {
  const current = useCurrent()
  if (!current) return null
  return getSessionCopyText(current)
}

export default useSessionCopyText
